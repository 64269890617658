<template>
  <div class="text-center my-4">
    上一篇: <a :href="'/cases/'+ prev.id" rel="prev" v-if="prev">{{ prev.title }}</a> <span v-else>没有了</span>
    下一篇: <a :href="'/cases/'+ next.id" rel="next" v-if="next">{{ next.title }}</a> <span v-else>没有了</span>
  </div>
</template>
<script>
import cases from "@/cases";

export default {
  name: 'CasesFooter',
  props: {
    index: null,
  },
  data(){
    return{
      next: null,
      prev: null,
    }
  },
  mounted() {
    this.next = cases[this.index - 1] ?? null
    this.prev = cases[this.index + 1] ?? null
  }
}
</script>
<style scoped>

a {
  color: #428bca;
  text-decoration: none;
}
</style>